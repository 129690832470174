.RobotProductsContainer {
	padding: 40px;
	background-color: #ffffff;
	text-align: center;
}

.RobotProducts-card-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* Display 3 cards in a row */
	gap: 20px;
	justify-content: center;
	padding: 20px;
	font-family: 'Bai Jamjuree', sans-serif;
}

/* Individual card styling */
.RobotProducts-card {
	width: 100%;
	height: 250px; /* Decreased height */
	perspective: 1000px;
	cursor: pointer;
	position: relative;
	border-radius: 12px;
	background: linear-gradient(145deg, #e6e6e6, #ffffff);
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), -4px -4px 10px rgba(255, 255, 255, 0.7); /* Box shadow for depth */
	overflow: hidden;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.RobotProducts-card:hover {
	transform: scale(1.05); /* Slight enlargement on hover */
	box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.15), -6px -6px 15px rgba(255, 255, 255, 0.9); /* Darker shadow on hover */
}

/* Inner card flip animation */
.RobotProducts-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	transition: transform 0.6s;
	transform-style: preserve-3d;
}

.RobotProducts-card:hover .RobotProducts-card-inner {
	transform: rotateY(180deg);
}

/* Front side of the card */
.RobotProducts-card-front {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	color: #333;
}

/* Front image styling */
.RobotProducts-card-front img {
	width: 100%;
	height: 150px; /* Decreased height */
	object-fit: contain;
	border-radius: 8px;
	background: #f0f0f0; /* Light background for image container */
	box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.1);
}

/* Title under image */
.RobotProducts-card-front h3 {
	margin-top: 12px;
	font-size: 16px; /* Decreased font size */
	text-align: center;
	color: #555;
	font-weight: 600;
}

/* Back side of the card */
.RobotProducts-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	transform: rotateY(180deg);
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(145deg, #1e1e1e, #000000); /* Vibrant gradient */
	color: white;
	border-radius: 12px;
}

.RobotProducts-card-back button {
	padding: 8px 16px;
	font-size: 14px; 
	border: none;
	background-color: #ffffff;
	color: #1e1e1e;
	font-weight: bold;
	cursor: pointer;
	border-radius: 20px;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
	transition: background-color 0.3s, color 0.3s;
}

.RobotProducts-card-back button:hover {
	background-color: #1e1e1e;
	color: #ffffff;
	border: solid 1px white;
}

/* Modal overlay styling */
.RobotProducts-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

/* Modal window styling */
.RobotProducts-modal {
	background-color: #fff;
	padding: 20px;
	border-radius: 12px;
	width: 80%;
	max-width: 600px;
	position: relative;
	opacity: 0;
	transform: scale(0.9);
	animation: fadeInScale 0.4s forwards;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}

.RobotProducts-modal h2 {
	margin-top: 0;
}

.RobotProducts-modal p {
	white-space: pre-line;
	line-height: 1.6;
	color: #444;
}

.RobotProducts-modal button {
	margin-top: 10px;
	padding: 8px 16px;
	font-size: 16px;
	border: none;
	background-color: #1e1e1e;
	color: white;
	cursor: pointer;
	border-radius: 4px;
}

@keyframes fadeInScale {
	from {
		 opacity: 0;
		 transform: scale(0.9);
	}
	to {
		 opacity: 1;
		 transform: scale(1);
	}
}