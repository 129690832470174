.heroContainer {
	background-image: url('https://www.hansonrobotics.com/wp-content/uploads/2020/09/IMG_0832.jpg');
	background-size: cover;
	background-position: center;
	height: calc(100vh - 111px);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	color: white;
	text-align: center;
	padding: 20px;
}

.heroContainer::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1;
}

.heroContent {
	position: relative;
	z-index: 2;
	max-width: 800px;
}

.heroQuote {
	font-size: 3rem;
	font-family: 'Bai Jamjuree', sans-serif;
	font-weight: bold;
	margin-bottom: 40px;
}

.heroDescription {
	font-size: 1.25rem;
	font-family: 'Bai Jamjuree', sans-serif;
	line-height: 1.5;
}