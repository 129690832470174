@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;600&display=swap');

.navbarContainer {
	background-color: white;
	color: black;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 50px;
	position: relative;
	transition: background-color 0.5s ease;
}

.logoSection {
	flex: 1;
	display: flex;
	align-items: center;
	gap: 20px;
}

.logoImg {
	width: 80px;
}

.logoText {
	color: black;
	font-size: 2rem;
	font-weight: bold;
	font-family: 'Bai Jamjuree', sans-serif;
	text-transform: uppercase;
	margin: 0;
	transition: transform 0.3s ease;
}

.navLinks {
	list-style: none;
	display: flex;
	gap: 40px;
	margin: 0;
	padding: 0;
}

.navItem a {
	color: grey;
	text-decoration: none;
	font-size: 1.1rem;
	font-family: 'Bai Jamjuree', sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	transition: color 0.3s ease, transform 0.2s ease;
	position: relative;
}

.navItem a::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: black;
	bottom: -5px;
	left: 0;
	transform: scaleX(0);
	transform-origin: right;
	transition: transform 0.3s ease;
}

.navItem a:hover {
	color: black;
	transform: scale(1.05);
}

.navItem a:hover::before {
	transform: scaleX(1);
	transform-origin: left;
}

.navItem a.active {
	color: black;
}

.navItem a.active::before {
	transform: scaleX(1);
	transform-origin: left;
}

.navItem a:active {
	transform: scale(0.95);
	transition: transform 0.1s ease;
}