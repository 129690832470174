.footerContainer {
	background-color: #000;
	color: #fff;
	padding: 40px 60px;
	text-align: left;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-family: 'Bai Jamjuree', sans-serif;
 }
 
 .footerSections {
	display: flex;
	justify-content: space-between;
	gap: 100px;
	width: 100%;
 }
 
 .footerSection {
	flex: 1;
 }
 
 .footerTitle {
	font-size: 1.2rem;
	margin-bottom: 15px;
	font-weight: bold;
 }
 
 .footerAddress {
	margin: 10px 0;
	line-height: 25px;
 }
 .footerEmail {
	margin-top: 20px; /* Space above the email */
}

.footerEmail a {
	color: #fff; /* White text color for the email */
	text-decoration: none; /* Remove underline */
}

.footerEmail a:hover {
	text-decoration: underline; /* Underline on hover */
}

 .footerList {
	list-style: none;
	padding: 0;
 }
 
 .footerList li {
	margin: 10px 0;
	position: relative;
	padding-bottom: 10px;
 }
 
 .footerList li:not(:last-child)::after {
	content: "";
	display: block;
	height: 1px;
	background-color: #ffffff3f;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
 }
 
 .footerList li:hover {
	color: #fff;
	transform: translateX(4px);
	transition: color 0.3s ease, transform 0.3s ease;
	cursor: pointer;
 }
 
 .newsletter {
	display: flex;
	flex-direction: column;
	align-items: start;
 }
 
 .newsletterInput {
	padding: 10px;
	border: none;
	border-radius: 5px;
	margin-bottom: 10px;
	width: 200px;
 }
 
 .subscribeButton {
	padding: 10px 15px; /* Padding for button */
	background-color: #000; /* Black background */
	color: #fff; /* White text */
	border: 1px solid #fff; /* White border */
	border-radius: 5px; /* Rounded corners */
	cursor: pointer; /* Pointer cursor */
	margin-top: 20px;
	transition: scale 0.3s ease, color 0.3s ease; /* Smooth transition */
 }
 
 .subscribeButton:hover {
	scale: 1.1;
 }
 
 .footerCopyright {
	border-top: 1px solid #fff;
	margin-top: 20px;
	padding-top: 10px;
	width: 100%;
	text-align: center;
	font-size: 0.9rem;
 }
 