.sparePartsContainer {
	padding: 40px;
	background-color: #ffffff;
	text-align: center;
}

.sparePartsTitle {
	font-family: 'Bai Jamjuree', sans-serif;
	font-size: 2.5rem;
	margin-bottom: 20px;
	color: #333333;
}

.carouselContainer {
	padding: 20px;
	overflow: hidden;
	position: relative;
}

.carouselTrack {
	display: flex;
	animation: scroll 20s linear infinite;
	transition: transform 0.3s ease-in-out;
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

.carouselTrack:hover {
	animation-play-state: paused;
}

.carouselCard {
	background: #000;
	border: 1px solid #ccc;
	border-radius: 10px;
	box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
	padding: 20px;
	min-width: 300px;
	margin-right: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.carouselCard:hover {
	transform: scale(1.1);
	box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2);
}

.carouselImage {
	width: 100%;
	height: 180px;
	object-fit: cover;
	border-radius: 8px;
	border: 2px solid #333;
}

.carouselTitle {
	font-family: 'Bai Jamjuree', sans-serif;
	font-size: 1.5rem;
	margin: 15px 0 10px;
	color: #fff;
}

.carouselDescription {
	font-family: 'Bai Jamjuree', sans-serif;
	font-size: 0.9rem;
	text-align: start;
	color: #c8c8c8;
	margin: 5px 10px;
}
