.contactUsContainer {
	padding: 40px;
	background: #000;
	border-radius: 12px;
	box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
	margin: 20px auto;
	max-width: 800px;
	color: #fff;
	position: relative;
	overflow: hidden;
 }
 
 .contactUsTitle {
	font-family: 'Bai Jamjuree', sans-serif;
	font-size: 2.5rem;
	margin-bottom: 20px;
	text-align: center;
	animation: fadeIn 0.5s ease-in-out;
 }
 
 .contactUsDescription {
	font-family: 'Bai Jamjuree', sans-serif;
	font-size: 1.2rem;
	margin-bottom: 30px;
	text-align: center;
	opacity: 0.8;
 }
 
 .contactForm {
	display: flex;
	flex-direction: column;
 }
 
 .formInput,
 .formTextarea {
	padding: 15px;
	margin: 10px 0;
	border: none;
	border-radius: 8px;
	font-size: 1rem;
	transition: all 0.3s ease;
	background-color: rgba(255, 255, 255, 0.1);
	color: #fff;
 }
 
 .formInput:focus,
 .formTextarea:focus {
	background-color: rgba(255, 255, 255, 0.2);
	outline: none;
 }
 
 .formTextarea {
	resize: none;
	height: 100px;
 }
 
 .submitButton {
	margin-top: 20px;
	padding: 15px;
	background-color: #fff;
	color: #000;
	border: none;
	border-radius: 8px;
	font-size: 1.2rem;
	cursor: pointer;
	transition: all 0.3s ease;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
 }
 
 .submitButton:hover {
	background-color: #ccc;
	color: #000;
	transform: scale(1.05);
 }
 

 @keyframes fadeIn {
	from {
	  opacity: 0;
	  transform: translateY(-20px);
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
 }
 